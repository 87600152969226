/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object } from 'prop-types';
import { useUIDSeed } from 'react-uid';
import mbpLogger from 'mbp-logger';
import useStyles from 'isomorphic-style-loader/useStyles';
import CTA from '../../../SubComponents/CTA/CTA';
import SixUpCss from './SixUp.module.css';

// helpers
import cmsCssClassesToString from '../../../../../EnterpriseDesignSystem/helpers/cmsCssClassesToString';

const SixUp = ({
    data,
}) => {
    if (!Object.keys(data)?.length) {
        return mbpLogger.logError({
            appName: process.env.npm_package_name,
            component: 'Tiles/SixUp/SixUp.js',
            message: 'No data available to render component',
        });
    }
    const seed = useUIDSeed();
    useStyles(SixUpCss);
    const { ga_constants = {}, tile = [] } = data || {};
    const defaultParams = 'auto=webp';
    return (
        <div className={SixUpCss.container} data-testid="sixUpTiles">
            {tile.map(({
                image = {}, cta = {}, sub_heading = '', css_classes = ['text2', 'u-margin-top-14'],
            }) => (
                <div key={seed} className={`${SixUpCss.imageWrapper}`} role="presentation">
                    {cta.link?.href && (
                        <CTA data={{
                            link: cta.link,
                            tracking_event_category: ga_constants?.tracking_event_category,
                            tracking_event_action: ga_constants?.tracking_event_action,
                            tracking_event_label: cta?.tracking_event_label,
                            presentation: 'tertiary',
                        }}
                        >
                            <img className={SixUpCss.image} src={`${image?.url}?${defaultParams}`} alt={image?.description} loading="lazy" />
                            {sub_heading && <p className={cmsCssClassesToString(css_classes.length > 0 ? css_classes : ['text2', 'u-margin-top-14'])}>{sub_heading}</p>}
                            {/* {sub_heading && <div className={SixUpCss.subHeading}>{sub_heading}</div>} */}
                        </CTA>
                    )}
                </div>
            ))}
        </div>
    );
};

SixUp.propTypes = {
    data: object.isRequired,
};

export default SixUp;
